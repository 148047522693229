<template>
  <div class="hotel">
    <img class="background" src="../../assets/image/background.png" alt srcset />
    <header>
      <img src="../../assets/image/logo.png" alt srcset />
    </header>
    <van-divider :style="dividerStyle">酒店交通</van-divider>
    <div class="card">
      <div class="title">中国浙江﹒杭州君尚云郦酒店</div>
      <div class="address">地址：浙江省杭州市江干区丁兰街道临丁路1188号</div>
      <div class="address">参会代表享有大会住宿协议价格（具体请联系酒店会议销售人员）</div>
      <div class="address">销售部电话：15355022554 &nbsp;&nbsp;何亮亮</div>
    </div>
    <div class="card">
      <div class="title">酒店交通：</div>
      <div class="address">机场：杭州萧山国际机场，距酒店40公里</div>
      <div class="address">火车站：杭州东站，距酒店12公里</div>
      <div class="address">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        杭州站，距酒店17公里</div>
      <div class="address">地铁站：5号线西文街站，距酒店8公里</div>
      <div class="address">公交车：335路丁兰路口站，距酒店1.5公里</div>
    </div>
    <div class="card">
      <div class="title">城市特色景点：</div>
      <div class="address">杭州半山国家森林公园，距酒店1.3公里</div>
      <div class="address">杭州西湖，距酒店18.5公里</div>
      <div class="address">岳飞庙，距酒店19公里</div>
      <div class="address">灵隐寺，距酒店26公里</div>
      <div class="address">西溪国家湿地公园，距酒店27公里</div>
    </div>
    <div class="card">
      <div class="title">附近餐饮：</div>
      <div class="address">水知灵食府，300米</div>
      <div class="address">开化人家，341米</div>
      <div class="address">沙县小吃，350米</div>
      <div class="address">957街坊菜(田园店)，517米</div>
      <div class="address">肯德基(丁桥物美餐厅)，1800米</div>
    </div>
    <div class="card">
      <div class="title">附近医院：</div>
      <div class="address">杭州市中医院丁桥院区，1.4公里</div>
      <div class="address">杭州师范大学附属医院半山分院，3.1公里</div>
      <div class="address">解放军联勤保障部队第903医院机场路园区，10公里</div>
      <div class="address">浙江省人民医院，12公里</div>
      <div class="address">杭州市儿童医院，12公里</div>
      <div class="address">浙江大学附属第二医院，15公里</div>
    </div>
    <div class="card">
      <div class="title">附近银行：</div>
      <div class="address">中国工商银行24小时自助银行(天鹤路)，400米</div>
      <div class="address">中国工商银行（杭州丁桥支行），989米</div>
      <div class="address">杭州联合银行24小时自助银行，998米</div>
    </div>
    <div class="card">
      <div class="title">住宿：</div>
      <div class="address">杭州湖山雷迪森庄园（星级），1公里</div>
      <div class="address">Zsmart智尚酒店半山国家森林公园店（快捷），1.4公里</div>
      <div class="address">如家酒店one丁兰广场店（快捷），2.4公里</div>
      <div class="address">汉庭酒店丁兰广场（快捷），2.4公里</div>
      <div class="address">汉庭优佳酒店杭州半山店（快捷），2.9公里</div>
    </div>
    <div class="footer-fill"></div>
    <el-footer :index="1" />
  </div>
</template>

<script>
import footer from "../../components/footer/footer-tempate.vue";
export default {
  components: {
    "el-footer": footer
  },
  data() {
    return {
      dividerStyle: {
        color: "#FFFFFF",
        borderColor: "#fff",
        padding: "0 18px",
        "font-size": "16px"
      }
    };
  },
  methods: {}
};
</script>

<style lang="less" scoped>
.hotel {
  min-height: 100vh;
  // background-image: url("../../assets/image/background.png");
  // background-repeat: no-repeat;
  // background-size: 100% 100%;
  .background {
    margin: 0 auto;
    width: 100%;
    height: 100%;
    position: fixed;
    background-size: 100% 100%;
    z-index: -1;
  }
  header {
    padding: 10px 0;
    width: 80%;
    margin: 0 auto;
    img {
      max-width: 100%;
    }
  }
  .box {
    height: 46px;
  }
  .van-hairline--bottom::after {
    border-bottom-width: 0;
  }
  /deep/ .van-nav-bar .van-icon {
    color: #fff;
  }
  .van-nav-bar {
    background-color: transparent;
  }
  .card {
    width: 92%;
    margin: 0 4% 12px 4%;
    padding: 20px 12px;
    box-sizing: border-box;
    // height: 140px;
    color: #fff;
    border: 0.5px solid #fff;
    background-color: #6a3853;
    .title {
      font-size: 16px;
      font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
      font-weight: 400;
    }
    .address {
      margin-top: 12px;
      font-size: 14px;
    }
  }
}
.footer-fill {
  height: 37px;
}
</style>